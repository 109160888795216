body {
  background: #000000;
  font-family: 'Helvetica Neue', Helvetica , Arial, sans-serif;
  font-size: 16px;
  margin: 0;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1 0 auto;
  /*padding: 1.5em 1.5em 0;*/
  width: 100%;
}

.btn {
  line-height: normal;
  padding: 7px 17px;
  font-weight: 400;
  font-size: 1.1rem;
  border-radius: 3px;
  color: #ffffff;
  border: 0;
}

a {
  text-decoration: none;
}

a.btn:hover {
  text-decoration: none !important;
  color: #ffffff
}

.btn-red {
  background-color: #e50914;
}

.btn-right {
  float: right;
}

.btn-large
{
  padding: 14px 3rem 14px 2rem;
  font-size: 1.75rem;
}

.btn-login
{
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  margin: 24px 0 12px;
  padding: 16px;
}

