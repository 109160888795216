.header-wrapper
{
  background-image: linear-gradient(to bottom, #000 75%, transparent);
  position: fixed;
  width: 100vw;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 108px;
}

.header
{
  height: 80px;
  margin: 0 4.5rem;
  padding-top: .5rem;
  max-width: 1920px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-nav
{
  display: flex;
  align-items: center;
}

.avatar
{
  width: 40px;
  height: 40px;
  margin-left: 2rem;
}