.login-container
{
  height: calc(100vh - 100px);
}

.login-container:before
{
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  opacity: 1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000), color-stop(15%, transparent));
  background-image: linear-gradient(to bottom, #000, transparent 15%);
}

.login-container:after
{
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  opacity: 1;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(15%, #000), to(transparent));
  background-image: linear-gradient(to top, #000 15%, transparent);
}

.login-card-background
{
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url("/images/background_small.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.login-body
{
  margin: 0 auto -236px;
  position: relative;
  min-height: 100vh;
  max-width: 450px;
  padding: 0 5%;
  z-index: 100;
}

.login-body:before
{
  content: "";
  height: 108px;
  display: block;
}

.login-form-main
{
  width: 100%;
  background: rgba(0,0,0,.75);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 60px 68px 40px;
  margin-bottom: 90px;
  min-height: 660px;
  box-sizing: border-box;
}

.login-form-main h1
{
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 28px;
}

.login-form-fields
{
  flex-grow: 1;
}

.form-main
{
  width: 100%;
}

.form-input
{
  padding-bottom: 16px;
}

.form-input input
{
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 0;
  color: #ffffff;
  line-height: 50px;
  padding: 16px 20px;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #333333;
  transition: background .1s ease-in-out;
  /*display: flex;*/
  /*justify-content: center;*/
  /*margin-top: 5em;*/
}

.form-input input:focus
{
  background: #454545;
  transition: background .1s ease-in-out;
}

input:focus
{
  outline: 0;
}

.form-input label
{
  color: #b3b3b3;
  position: relative;
  top: -2.2rem;
  left: 1.5rem;
  font-size: .9rem;
}

/*.form-input input*/
/*{*/
  /*width: calc(100% - 3em)*/
/*}*/

@media (min-width: 1200px)
{
  .login-card-background
  {
    position: absolute;
    background-image: url("/images/main_background.jpg");
  }
  .login-container:after
  {
    opacity: .925;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(5%, #000), to(transparent));
    background-image: linear-gradient(to right, #000 5%, transparent);
  }
}